import MaximumSpeedLogo from '../../assets/images/truck-scale-maximum-speed.png'
import CarbonFootprintLogo from '../../assets/images/truck-scale-carbon-footprint.png'
import TransactionCapacity from '../../assets/images/truck-scale-transaction-capacity.png'

const calculators = [
    {
        slug: 'maximum-speed',
        title: 'Maximum Speed Calculator for SURVIVOR® OTR-IMS Systems',
        cardDescription:
            'This calculator will help you determine the maximum speed trucks can cross the scale while maintaining accurate weight readings. Maximum speed is determined by the vehicle wheelbase and length of the scale to ensure the vehicle is completely on the scale for a minimum of 3 seconds.',
        formDescription:
            'This calculator will help you determine the maximum speed trucks can cross the scale while maintaining accurate weight readings. Maximum speed is determined by the vehicle wheelbase and length of the scale to ensure the vehicle is completely on the scale for a minimum of 1.5 seconds. To ensure Legal for Trade accuracy, the maximum vehicle speed on SURVIVOR® OTR-IMS scales cannot exceed 6 miles per hour.',
        imageSrc: MaximumSpeedLogo,
        imageAlt: 'Maximum Speed Calculator'
    },
    {
        slug: 'carbon-footprint',
        title: 'Value of Wasted Fuel and Carbon Footprint Calculator',
        cardDescription:
            'Use this calculator to see how much fuel is wasted, and its estimated cost, when vehicles idle while waiting in line to weigh loads on the scale. This will also show the potential carbon footprint reduction in kilograms of CO2',
        formDescription:
            'Use this calculator to see how much fuel is wasted, and its estimated cost, when vehicles idle while waiting in line to weigh loads on the scale. This will also show the potential carbon footprint reduction in kilograms of CO2',
        imageSrc: CarbonFootprintLogo,
        imageAlt: 'Carbon Footprint Calculator'
    },
    {
        slug: 'transaction-capacity',
        title: 'Potential Transaction Capacity Calculator',
        cardDescription:
            'This calculator will help you understand the potential increase to the number of transactions per day when using an in-motion truck scale. Review both the potential increase in trucks per hour and per day to help you plan future business growth.',
        formDescription:
            'This calculator will help you understand the potential increase to the number of transactions per day when using an in-motion truck scale. Review both the potential increase in trucks per hour and per day to help you plan future business growth. To ensure Legal for Trade accuracy, the maximum vehicle speed on SURVIVOR® OTR-IMS scales cannot exceed 6 miles per hour.',
        imageSrc: TransactionCapacity,
        imageAlt: 'Transaction Capacity Calculator'
    }
]

export default calculators
