import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import { TruckScaleTransactionCapacityValidation } from '../../../components/Forms/Validation'
import { ScaleToolsNumberInput } from '../../../components/Forms/ScaleToolsTextInput'
import TransactionCapacityLogo from '../../../assets/images/truck-scale-transaction-capacity.png'
import calculators from '../calculators'

const minimumTimeOnScale = 1.5
const superSecretMaxSpeedValue = 1.4667
const superSecretReductionOfTimeValue = 1.466667
const secondsInAHour = 3600
const legalMaximumSpeedInMph = 6

const calculator = calculators.find(x => x.slug === 'transaction-capacity')

const TransactionCapacityCalculator = () => {
    const {
        state = {
            averageTimeOnScaleInSeconds: 0,
            hoursOfOperationEachDay: 0,
            lengthOfScale: 0,
            lengthOfVehicleWheelbase: 0,
            maximumSpeedOverScaleInMph: 0,
            maximumSpeedOverScaleInMphDisplay: 0,
            minimumTimeOnScale: 0,
            numberOfTrucksPerDay: 0,
            potentialIncreaseInTrucksPerDay: 0,
            potentialIncreaseInTrucksPerDayDisplay: 0,
            potentialIncreaseInTrucksPerHour: 0,
            potentialIncreaseInTrucksPerHourDisplay: 0,
            reductionOfTimeOnScaleInSeconds: 0
        }
    } = useLocation()

    const [maximumSpeedOverScaleInMph, setMaximumSpeedOverScaleInMph] = useState(
        state.maximumSpeedOverScaleInMph
    )
    const [potentialIncreaseInTrucksPerHour, setPotentialIncreaseInTrucksPerHour] = useState(
        state.potentialIncreaseInTrucksPerHour
    )
    const [potentialIncreaseInTrucksPerDay, setPotentialIncreaseInTrucksPerDay] = useState(
        state.potentialIncreaseInTrucksPerDay
    )
    const [reductionOfTimeOnScaleInSeconds, setReductionOfTimeOnScaleInSeconds] = useState(
        state.reductionOfTimeOnScaleInSeconds
    )

    const maximumSpeedOverScaleInMphDisplay = Math.ceil(maximumSpeedOverScaleInMph)
    const potentialIncreaseInTrucksPerDayDisplay = Math.ceil(potentialIncreaseInTrucksPerDay)
    const potentialIncreaseInTrucksPerHourDisplay = Math.ceil(potentialIncreaseInTrucksPerHour)
    const reductionOfTimeOnScaleInSecondsDisplay = Math.ceil(reductionOfTimeOnScaleInSeconds)

    const isLegalForTrade =
        maximumSpeedOverScaleInMph > 0 && maximumSpeedOverScaleInMph <= legalMaximumSpeedInMph
    const isNotLegalForTrade =
        maximumSpeedOverScaleInMph > 0 && maximumSpeedOverScaleInMph > legalMaximumSpeedInMph

    const onCalculate = values => {
        const newMaxSpeedOverScale =
            (values.lengthOfScaleInFeet - values.lengthOfVehicleWheelbaseInFeet) /
            superSecretMaxSpeedValue /
            minimumTimeOnScale
        setMaximumSpeedOverScaleInMph(newMaxSpeedOverScale)

        const newReductionOfTimeOnScaleInSeconds =
            values.averageTimeOnScaleInSeconds -
            (values.lengthOfScaleInFeet + values.lengthOfVehicleWheelbaseInFeet) /
                (newMaxSpeedOverScale * superSecretReductionOfTimeValue)
        setReductionOfTimeOnScaleInSeconds(newReductionOfTimeOnScaleInSeconds)

        const totalTrucks = values.currentNumberOfTrucksPerDay / values.hoursOfOperationEachDay
        const totalTime =
            secondsInAHour /
            (values.averageTimeOnScaleInSeconds - newReductionOfTimeOnScaleInSeconds)
        const newPotentialIncreaseInTrucksPerHour = totalTime - totalTrucks
        setPotentialIncreaseInTrucksPerHour(newPotentialIncreaseInTrucksPerHour)

        const newPotentialIncreaseInTrucksPerDay =
            newPotentialIncreaseInTrucksPerHour * values.hoursOfOperationEachDay
        setPotentialIncreaseInTrucksPerDay(newPotentialIncreaseInTrucksPerDay)
    }

    const onReset = () => {
        setMaximumSpeedOverScaleInMph(0)
        setPotentialIncreaseInTrucksPerDay(0)
        setPotentialIncreaseInTrucksPerHour(0)
        setReductionOfTimeOnScaleInSeconds(0)
    }

    return (
        <div id="transaction-capacity-calculator">
            <div id="header">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontWeight: 'bold'
                    }}
                >
                    <img src={TransactionCapacityLogo} alt="Transaction Capcaity Logo" />

                    <div id="side-results">
                        <div>Minimum Time on Scale</div>
                        <h2>{minimumTimeOnScale} seconds</h2>

                        <div
                            className={`${isNotLegalForTrade &&
                                'not-legal-for-trade-speed'} ${isLegalForTrade &&
                                'legal-for-trade-speed'}`}
                        >
                            Maximum Speed Over Scale
                        </div>
                        <h2
                            className={`${isNotLegalForTrade &&
                                'not-legal-for-trade-speed'} ${isLegalForTrade &&
                                'legal-for-trade-speed'}`}
                        >
                            {maximumSpeedOverScaleInMphDisplay} mph
                        </h2>
                        <h3>
                            {isLegalForTrade && (
                                <span className="legal-for-trade-speed">Legal for Trade Speed</span>
                            )}

                            {isNotLegalForTrade && (
                                <span className="not-legal-for-trade-speed">
                                    Not Legal for Trade Speed
                                </span>
                            )}
                        </h3>

                        <div>Reduction of Time on Scale</div>
                        <h2>{reductionOfTimeOnScaleInSecondsDisplay} seconds</h2>
                    </div>
                </div>
                <p style={{ marginLeft: '10px' }}>{calculator.formDescription}</p>
            </div>

            <Formik
                initialValues={{
                    currentNumberOfTrucksPerDay: state.numberOfTrucksPerDay,
                    averageTimeOnScaleInSeconds: state.averageTimeOnScaleInSeconds,
                    hoursOfOperationEachDay: state.hoursOfOperationEachDay,
                    lengthOfScaleInFeet: state.lengthOfScale,
                    lengthOfVehicleWheelbaseInFeet: state.lengthOfVehicleWheelbase
                }}
                onSubmit={onCalculate}
                validationSchema={TruckScaleTransactionCapacityValidation}
            >
                {({ handleSubmit, isValid, resetForm, values }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Current Number of Trucks Per Day"
                                        name="currentNumberOfTrucksPerDay"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Average Time On Scale Length in Seconds"
                                        name="averageTimeOnScaleInSeconds"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Hours of Operation Each Day"
                                        name="hoursOfOperationEachDay"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Length of Scale in Feet"
                                        name="lengthOfScaleInFeet"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Length of Vehicle Wheelbase in Feet"
                                        name="lengthOfVehicleWheelbaseInFeet"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>
                            </form>

                            <div id="side-results-mobile">
                                <div>Minimum Time on Scale</div>
                                <h2>{minimumTimeOnScale} seconds</h2>

                                <div
                                    className={`${isNotLegalForTrade &&
                                        'not-legal-for-trade-speed'} ${isLegalForTrade &&
                                        'legal-for-trade-speed'}`}
                                >
                                    Maximum Speed Over Scale
                                </div>
                                <h2
                                    className={`${isNotLegalForTrade &&
                                        'not-legal-for-trade-speed'} ${isLegalForTrade &&
                                        'legal-for-trade-speed'}`}
                                >
                                    {maximumSpeedOverScaleInMphDisplay} mph
                                </h2>
                                <h3>
                                    {isLegalForTrade && (
                                        <span className="legal-for-trade-speed">
                                            Legal for Trade Speed
                                        </span>
                                    )}

                                    {isNotLegalForTrade && (
                                        <span className="not-legal-for-trade-speed">
                                            Not Legal for Trade Speed
                                        </span>
                                    )}
                                </h3>

                                <div>Reduction of Time on Scale</div>
                                <h2>{reductionOfTimeOnScaleInSecondsDisplay} seconds</h2>
                            </div>

                            <div id="transaction-capacity-result">
                                <h3>Result</h3>

                                <div>
                                    <p>Potential Increase in Trucks per Hour</p>
                                    <h2>{potentialIncreaseInTrucksPerHourDisplay} tph</h2>

                                    <p>Potential Increase in Trucks per Day</p>
                                    <h2>{potentialIncreaseInTrucksPerDayDisplay} tph</h2>

                                    <button
                                        disabled={!isValid}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Calculate
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            onReset()
                                            resetForm()
                                        }}
                                        className="reset-btn"
                                    >
                                        Reset
                                    </button>

                                    <Link
                                        className="btn btn-grey"
                                        to={{
                                            pathname: '/truck-scale/transaction-capacity/report',
                                            state: {
                                                averageTimeOnScaleInSeconds:
                                                    values.averageTimeOnScaleInSeconds,
                                                hoursOfOperationEachDay:
                                                    values.hoursOfOperationEachDay,
                                                lengthOfScale: values.lengthOfScaleInFeet,
                                                lengthOfVehicleWheelbase:
                                                    values.lengthOfVehicleWheelbaseInFeet,
                                                maximumSpeedOverScaleInMph,
                                                maximumSpeedOverScaleInMphDisplay,
                                                minimumTimeOnScale,
                                                numberOfTrucksPerDay:
                                                    values.currentNumberOfTrucksPerDay,
                                                potentialIncreaseInTrucksPerHour,
                                                potentialIncreaseInTrucksPerHourDisplay,
                                                potentialIncreaseInTrucksPerDayDisplay,
                                                potentialIncreaseInTrucksPerDay,
                                                reductionOfTimeOnScaleInSeconds
                                            }
                                        }}
                                    >
                                        View Report
                                    </Link>
                                </div>
                            </div>
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}

export default TransactionCapacityCalculator
